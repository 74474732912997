<template>
  <div class="rightLink">
    <div class="bottomDiv" style="padding:0px 25px;height:750px">
      <h5 class="title" style="margin-bottom: 5px;"><i class="glyphicon glyphicon-user"></i> 用户角色列表</h5>
      <!-- <el-button type="primary" @click="openAddUser" size="small"><i class="glyphicon glyphicon-plus"></i> 新增用户</el-button> -->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%;"
        height="630px"
        stripe
        size="medium"
        :header-row-style="{ color: '#333' }"
      >
        <el-table-column prop="user_number" label="序号" type="index" width="60" />
        <el-table-column prop="username" label="用户名" min-width="100" />
        <el-table-column prop="roles_name" label="角色" min-width="100" />
          <!-- <template #default="scope">
            <div v-for="(item, index) in roleList" :key="index">
              {{ scope.row.roles_name == item.id ? item.name : ""}}
            </div>
          </template> -->
        
        <!-- <el-table-column prop="is_active" label="用户状态" min-width="100">
          <template #default="scope">
            {{ scope.row.status == 1 ? "正常" : "暂停" }}
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱" min-width="200" />
        <el-table-column prop="cellphone" label="联系方式" min-width="180" />
        <el-table-column prop="id_number" label="身份证号" min-width="180" />
        <el-table-column prop="branch" label="部门" min-width="100" />
        <el-table-column prop="create_time" label="创建时间" min-width="180" />
         -->
        <el-table-column label="操作" min-width="180" fixed="right">
          <template #default="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-edit"></i> 编辑</el-button
            >     
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              ><i class="glyphicon glyphicon-trash"></i> 清除角色</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div v-dialogdrag>
        <el-dialog
        v-model="addRoleDiaLogFlag"
        :title="DiaLogFlagTitle"
        :before-close="handleClose"
        destroy-on-close
        width="40%"
      >
        <el-form
          :model="editUser"
          label-position="right"
          label-width="100px"
          :rules="editUserRules"
          ref="editUserDialog"
          
        >
          <!-- <el-form-item label="使用人" prop="realname">
            <el-input clearable v-model="editUser.realname"></el-input>
          </el-form-item> -->
          <el-row :gutter="20">
            <el-col :span="3"></el-col>
            <el-col :span="14">
              <el-form-item label="用户名" prop="username">
                <el-input disabled clearable v-model="editUser.username"></el-input>
              </el-form-item>
          
          <!-- <el-form-item label="密码" prop="password">
            <el-input
              clearable
              v-model="editUser.password"
              show-password
            ></el-input>
          </el-form-item> -->
          <el-form-item label="角色">
            <el-select v-model="editUser.roles_name" style="width:100%">
              <el-option
                v-for="(item, index) in roleList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
          </el-row>
          <!-- <el-form-item label="邮箱">
            <el-input clearable v-model="editUser.email"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input clearable v-model="editUser.cellphone"></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input clearable v-model="editUser.id_number"></el-input>
          </el-form-item>
          <el-form-item label="所属部门">
            <el-input clearable v-model="editUser.branch"></el-input>
          </el-form-item>
          <el-form-item label="是否启用">
            <el-radio-group v-model="editUser.status">
              <el-radio class="fromRadio" :label="true">正常</el-radio>
              <el-radio class="fromRadio" :label="false">暂停</el-radio>
            </el-radio-group>
          </el-form-item> -->
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="handleClose">关闭</el-button>
            <el-button type="primary" class="buttonPri" @click="submitEditUser"
              >确认</el-button
            >
          </span>
        </template>
      </el-dialog>
    </div>
    </div>
    
  </div>
</template>

<style scoped></style>

<script>
import { onMounted, reactive, toRefs, ref } from "vue";
import { role, user_roles_manage_g, user_roles_manage_p, delUser1 } from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
// import JSEncrypt from "jsencrypt";
export default {
  name: "sysConfiguser",
  setup() {
    // ref 弹框中的from
    const editUserDialog = ref(null);
    const data = reactive({
      page: 1,
      total: 0,
      current_page: 10,
      tableData: [], // 表格数据容器
      DiaLogFlagTitle: "", // 弹框title
      addRoleDiaLogFlag: false, // 角色添加弹框显示
      isAdd: "", // 1 是添加 0 是编辑
      loading: false,
      // 搜索
      searchData: {
        keyword: "",
      },
      // 用户的添加与编辑数据容器
      editUser: {
        roles_name: "",
        user_name:''
          // name:''
      },
      // 用户添加和编辑的表单校验
      editUserRules: {

        // username: [
        //   {
        //     required: true,
        //     message: "用户名不能为空",
        //     trigger: "blur",
        //   },
        // ],
        // password: [
        //   {
        //     required: true,
        //     message: "密码不能为空",
        //     trigger: "blur",
        //   },
        // ],
      },
      roleList: [],
    });
    onMounted(() => {
      getUserList();
      getRoleList();
    });
    // 用户查询
    const getUserList = () => {
      data.loading = true;
      
      const datas = {
        keyword: data.searchData.keyword,
        page: 20, // 动态
        current_page: 1,
      };
      user_roles_manage_g(datas)
        .then((res) => {
          console.log(res,'!~!!');
          data.tableData = res.data;
          data.total = res.total;
          data.loading = false;
        })
        .catch(() => {
          data.tableData = [];
          data.loading = false;
        });
    };
    // 用户编辑
    const handleEdit = (index, row) => {
      data.editUser = JSON.parse(JSON.stringify(row));
      data.isAdd = "0";
      data.DiaLogFlagTitle = "选择角色";
      data.addRoleDiaLogFlag = true;
      console.log(row);
    };
    // 关闭弹框
    const handleClose = () => {
      data.editUser = {
        username: "",
        roles_name:'',
  
      };
      data.addRoleDiaLogFlag = false;
    };
    // 打开添加用户弹框
    const openAddUser = () => {
      data.addRoleDiaLogFlag = true;
      data.DiaLogFlagTitle = "添加用户";
      data.isAdd = "1";
    };
    // 提交添加/编辑
    const submitEditUser = () => {
              const dataa1 = {
                username: data.editUser.username,
                roles_id: data.editUser.roles_name
              }
              user_roles_manage_p(dataa1).then((res) => {
                if (res) {
                  getUserList();
                  handleClose();
                  ElMessage({
                    type: "success",
                    message: "编辑成功",
                  });
                  data.editUser={}
                }
              });
            // }
          // });
          // break;
        // }
        // case "0": {
        //   // const pwd = rasEncryption(data.editUser.password);
        //   // if (pwd) {
        //   //   data.editUser.password = pwd;
        //   // } else {
        //   //   data.editUser.password = "";
        //   // }
        //   console.log(data.editUser,'data.editUser')
          
        //   console.log(dataa1,'dataa1');
        //   user_roles_manage_p(dataa1).then((res) => {
        //     if (res) {
        //       console.log(res,'用户角色res')

        //       getUserList();
        //       ElMessage({
        //         type: "success",
        //         message: "编辑成功",
        //       });
        //       data.addRoleDiaLogFlag = false;
        //     }
        //   });
        //   break;
        // }
      // }
    };
    // 用户删除
    const handleDelete = (index, row) => {
      console.log(row,'111');
      const dataa = {
        username: row.username,
        roles_name: row.roles_name
      };
      ElMessageBox.confirm("您确认要删除用户吗?", "警告", { 
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        console.log(dataa,'000');
        delUser1(dataa).then((res) => {
          if (res) {
            getUserList();
            ElMessage({
              type: "success",
              message: "删除成功",
            });
          }
        });
      });
    };
    // 获取角色列表
    const getRoleList = () => {
      role({
        keyword: "",
        page: 100,
        current_page: 1,
      }).then((res) => {
        if (res) {
          data.roleList = res.data;
          console.log(res.data,'0000000')
        }
      });
    };

    return {
      ...toRefs(data),
      getUserList,
      handleEdit,
      handleClose,
      submitEditUser,
      handleDelete,
      editUserDialog,
      openAddUser,
    };
  },
};
</script>
